<template>
	<div class="no-auth">
		<div v-if="isAuth">
			<slot></slot>
		</div>
		<el-card v-else>
			<div class="content">
				<div class="left">
					<img src="https://cdn.dkycn.cn/melyshop/image/20210802-91f98c80-0a5e-48d8-8ade-2100fb67ea79.png">
					<div class="info">
						<div class="tit">小程序直播</div>
						<div class="tip">通过小程序直播管理功能模块，你可以获取在小程序后台创建的直播间数据，可以管理直播间商品库存数据</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" @click="handleUse">开始使用</el-button>
				</div>
			</div>
			<div style="margin-top:25px">
				<div class="desc-title">1/同步小程序直播间数据</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210802-223a44b8-0e6c-4b1d-8e18-41d0b37c0159.png"></img>
				<div class="desc-title">2/统计直播间交易转化</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210802-c957f641-da9e-429e-a952-adfb348e8174.png"></img>
				<div class="desc-title">3/直播间实时数据监测</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210803-3b58dcc1-4370-4b28-9f1a-5916c60edbde.png"></img>
				<div class="desc-title">4/直播间分组展示</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210803-4445c3b9-9451-4f07-bcf0-18d6e09f0f5f.png"></img>
				<div class="desc-title">5/同步直播间商品库数据</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210803-69f6b82a-54b0-45f5-97d7-ef3327e6dfe3.png"></img>
				<div class="desc-title">6/直播间营销</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210803-3540c90e-82e2-4955-bafc-a990cd419c59.png"></img>
			</div>


			<el-dialog title="提示" :visible.sync="visible" width="480px">

				<div class="dialog-tip">
					<div class="icon">
						<i class="el-icon-warning"></i>
					</div>
					<div class="txt">小程序无直播间插件权限，请扫描下方二维码，联系客服为你更新小程序版本</div>
				</div>

				<img src="@/assets/img/20210719171848.jpg" class="dialog-rwm"></img>

				<span slot="footer" class="dialog-footer">
					<el-button @click="visible = false" size="mini">关闭</el-button>
				</span>
			</el-dialog>


			<el-dialog title="小程序API秘钥配置" :visible.sync="secretVisible" width="750px">

				<div class="secret-tip">
					请登录小程序：{{nickName}}，生成API密钥并复制粘贴到下方输入框内
					<el-link type="primary" style="margin-left: 20px;" href="https://mp.weixin.qq.com/" target="_blank">
						去登录</el-link>
					<el-link type="primary" style="margin-left: 10px;"
						href="https://jusnn6k8al.feishu.cn/docs/doccnVlVGl4sveaa6F2lKhDVxJc" target="_blank">如何获取API密钥?
					</el-link>
				</div>

				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
					<el-form-item label="小程序API秘钥" prop="secret">
						<el-input v-model="ruleForm.secret" style="width: 350px;" placeholder="请将小程序后台生成的API秘钥粘贴到此处">
						</el-input>
					</el-form-item>
				</el-form>

				<span slot="footer" class="dialog-footer">
					<el-button @click="secretVisible = false" size="mini">取消</el-button>
					<el-button type="primary" size="mini" :loading="loading" @click="handleSave">保存</el-button>
				</span>
			</el-dialog>

		</el-card>



	</div>
</template>

<script>
	import {
		mallWxAppAndPayInfo
	} from '@/api/login.js'

	import {
		mallFreeVersionConfigInfo
	} from '@/api/goods.js';
	
	import {
		setWxAppSecret
	} from '@/api/sv1.0.0.js';

	export default {
		components: {},
		data() {
			return {
				state: 0,
				isUserPlugIn: false,

				visible: false,
				secretVisible: false,
				nickName: '',
				loading: false,

				ruleForm: {
					secret: ''
				},
				rules: {
					secret: [{
						required: true,
						message: '请输入密钥',
						trigger: 'blur'
					}]
				}
			};
		},
		computed: {
			isAuth() {
				return this.state !== 0 && this.state !== 3 && this.isUserPlugIn
			}
		},
		beforeMount() {
			this.getData();
		},
		methods: {
			async getData(callback) {
				try {
					const res = await mallWxAppAndPayInfo();

					if (callback) {
						callback(res.Result);
						return;
					}

					this.state = res.Result.State;
					this.isUserPlugIn = res.Result.IsUserPlugIn;
				} catch (e) {
					//TODO handle the exception
				}
			},
			handleUse() {
				this.getData(res => {
					if (res.State === 0) {
						this.toAuthMiniprogram()
					} else if (!res.IsUserPlugIn) {
						this.visible = true;
					} else if (res.State === 3) {
						this.secretVisible = true;
						this.nickName = res.NickName;
					} else {
						this.getData();
					}
				})
			},
			async toAuthMiniprogram() {

				try {
					let res = await mallFreeVersionConfigInfo()
					this.url = res.Result.WxAppAuthUrl;

					this.$confirm('请先授权绑定商城端使用的微信小程序', '提示', {
						confirmButtonText: '去授权',
						cancelButtonText: '关闭',
						type: 'warning'
					}).then(() => {
						window.open(this.url)
					}).catch(() => {});
				} catch (e) {}

			},
			handleSave() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.sureSave()
					} else {
					}
				});
			},
			async sureSave(){
				this.loading = true;
				try{
					const res = await setWxAppSecret({
						AppSecret: this.ruleForm.secret
					})
					
					this.secretVisible = false;
					this.getData();
				}catch(e){
					//TODO handle the exception
				}finally{
					this.loading = false;
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.desc-content{
		font-size: 14px;
		color: #606266;
		line-height: 20px;
		
		>div{
			margin-bottom: 5px;
		}
	}
	
	.content {
		display: flex;
		overflow: hidden;
		padding: 15px;
		background-color: #f8f8f9;

		.left {
			flex: 1 1 auto;
			overflow: hidden;

			display: flex;

			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}

			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;

				.tit {
					font-size: 14px;
					font-weight: bold;
				}

				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}

		.right {
			flex: 0 0 auto;
		}
	}

	.dialog-tip {
		display: flex;

		.icon {
			flex: 0 0 auto;
			font-size: 16px;
			color: #e7a43c;
			line-height: 22px;
			margin-top: 1px;
			margin-right: 10px;
		}

		.txt {
			flex: 1 1 auto;
			font-size: 14px;
			line-height: 22px;
		}

	}

	.dialog-rwm {
		display: block;
		width: 300px;
		margin: 30px auto 0;
	}

	.secret-tip {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
	}
</style>

<template>
	<no-auth>
		<div style="background: #fff;padding: 15px;">
			<div class="filter-container">
				<!-- 代理等级 -->
				<div class="filter-item">
					<label class="label">关键字: </label>
					<el-input v-model="searchKey" @keyup.enter.native="goodsSearch" placeholder="分组名称" style="width: 200px;;margin-right: 10px;"
					 clearable></el-input>
				</div>
				<div class="filter-item">
					<el-button type="primary" style="margin-left:25px;width:90px;" @click="goodsSearch">查询</el-button>
					<el-button type="primary" style="margin-left:25px;width:90px;" @click="eidtGroup({})">添加</el-button>

				</div>

			</div>
			<div style="margin-bottom: 15px;">默认分组：设置为默认分组后，小程序后台创建的直播间将自动归属于默认分组下</div>
			<!-- 表格 -->
			<div class="table-container">
				<el-table :data="groupData" v-loading="loading">
					<el-table-column prop="GroupName" label="分组名称">
						<template slot-scope="scope">
							<div>{{scope.row.GroupName}} <span v-if="scope.row.IsDefault" style="color:#F56C6C;">(默认)</span></div>
						</template>
					</el-table-column>
					<el-table-column label="分组直播间">
						<template slot-scope="scope">
							<div v-if="scope.row.LiveRoomCount ==0">{{scope.row.LiveRoomCount}}</div>
							<div v-else style="color: #409EFF;cursor:pointer" @click="jumpToShop(scope.row)">{{scope.row.LiveRoomCount}}</div>
						</template>
					</el-table-column>

					<el-table-column label="列表隐藏">
						<template slot-scope="scope">
							<div>{{scope.row.IsHidden?'是':'否'}}</div>
						</template>
					</el-table-column>

					<el-table-column prop="AddTime" label="创建时间"></el-table-column>
					<el-table-column label="操作" width="240">
						<template slot-scope="scope">
							<el-button type="text" style="margin-right: 10px;" @click='Editdefault(scope.row)'>
								<span v-if="scope.row.IsDefault" style="color: #F56C6C;">取消默认分组</span>
								<span v-else  style="color: #409EFF;">设为默认分组</span>
							</el-button>
							<el-button type="text" @click="eidtGroup(scope.row)">编辑</el-button>

							<el-button type="text" @click="deleteChose(scope.row)" style="color:#f00;margin-left: 10px;">删除</el-button>

						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
					</div>
					<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div>
			</div>

			<!-- 商品名称编辑的弹框 -->
			<el-dialog :visible.sync="editVisable" width="50%" title="添加分组" :show-close="false">
				<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="分组名称:" prop="name">
							<el-input v-model="ruleForm.name" placeholder="最多输入16个字" maxlength="16" clearable style="width:300px"></el-input>
						</el-form-item>
						<el-form-item label="列表隐藏:" prop="IsHidden">
							<el-radio-group v-model="ruleForm.IsHidden">
								<el-radio :label="true">是</el-radio>
								<el-radio :label="false">否</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-form>
					<!-- <label class="label">分组名称: </label>
					<el-input v-model="groupName" placeholder="最多输入16个汉字" maxlength="16" clearable style="flex:1">
					</el-input> -->
				</div>

				<div style="text-align: center;margin-top:30px;">
					<el-button style="margin-right:30px;width: 120px;" @click="editVisable=false">关闭</el-button>
					<el-button type="primary" style="width: 120px;" @click="saveChange('ruleForm')" :loading="loading">保存</el-button>
				</div>
			</el-dialog>
		</div>
	</no-auth>
</template>

<script>
	import {
		liveroomgroup,
		liveroomgroupSave,
		liveroomgroupDelete,
		liveroomgroupDefault
	} from '@/api/TurnTomySelf'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	
	import noAuth from './components/noAuth.vue';
	
	export default {
		components: {
			buttonPermissions,
			noAuth
		},
		data() {
			var checkName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入分组名称'));
				} else if (!value.trim()) {
					return callback(new Error('请输入分组名称'));
				} else {
					return callback()
				}
			};
			return {
				ruleForm: {
					name: '',
					IsHidden: false,
					SortAsc: 0
				},
				rules: {
					name: [{
							required: true,
							// message: '请输入分组名称',
							trigger: 'blur',
							validator: checkName
						},
						{
							min: 1,
							max: 16,
							message: '最多输入16个字',
							trigger: 'blur'
						}
					]
				},
				groupData: [],
				searchKey: '',
				loading: false,
				editVisable: false,
				groupName: "",
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				eidtGroupData: {},
				exportUrl: config.EXPORT_URL,
				OrderBy: '',
				IsAsc: '',

			};
		},
		beforeMount() {
			this.getList()
		},
		methods: {
			async Editdefault(record){
				try{
					this.loading = true
					record.IsDefault = !record.IsDefault
					let data = {
						Id:record.Id,
						IsDefault : record.IsDefault
					}
					// console.log(data,'888888')
					let result = await liveroomgroupDefault(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.getList()
					}
				}catch(err){
					
				}finally{
					this.loading = false
				}
			},
			//限制输入
			OnlyNumber(obj, index) {
				obj = obj.replace(/[^\.\d]/g, '');
				obj = obj.replace('.', '');
				this.groupData[index].SortAsc = obj
			},
			jumpToShop(val) {
				let id = String(val.Id)
				this.$router.push({
					path: '/broadCast/index',
					query: {
						Id: [id]
					}
				})
			},
			// 获取列表数据
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey, // 搜索关键字
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc
					}
					let result = await liveroomgroup(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];

				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			goodsSearch() {
				this.currentPage = 1;
				this.getList();
			},
			eidtGroup(item) {
				if(item.Id){
					this.eidtGroupData = item;
					this.ruleForm.name = item.GroupName ? item.GroupName : ''
					this.ruleForm.IsHidden = item.IsHidden
				}
				else{
					this.eidtGroupData.Id = 0
					this.ruleForm.name = ''
					this.ruleForm.IsHidden = false
				}
				this.editVisable = true
				this.$nextTick(() => {
					this.$refs['ruleForm'].clearValidate();
				})
			},
			deleteChose(record) {
				this.$confirm('是否确认删除分组?删除后不可恢复', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					try {
						let result = await liveroomgroupDelete({
							Id: record.Id
						})
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
						}
					} catch (err) {

					} finally {

					}


				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					this.currentPage = 1;
					setTimeout(() => {
						this.goodsSearch();
					}, 500)

				})


			},
			async saveChange(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.loading = true
							let data = {
								Id: this.eidtGroupData.Id ? this.eidtGroupData.Id : 0,
								GroupName: this.ruleForm.name,
								IsHidden: this.ruleForm.IsHidden,
							}
							let result = await liveroomgroupSave(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.editVisable = false;
							}

						} catch (e) {
							console.log(e)
						} finally {
							this.goodsSearch();
							setTimeout(() => {
								this.loading = false
							}, 500)

						}
					} else {
						return false;
					}
				})


			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
	.exportBtn {
		position: relative;
	}

	.exportInput {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 90px;
	}

	#inputsId {
		display: inline-block;
		height: 35px;
		width: 90px;
	}
</style>
